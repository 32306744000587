import image1 from '../../../images/photoProjects/nikonf3test/1.jpg';
import image2 from '../../../images/photoProjects/nikonf3test/2.jpg';
import image3 from '../../../images/photoProjects/nikonf3test/3.jpg';
import image4 from '../../../images/photoProjects/nikonf3test/4.jpg';

export const photosDaily = [
	{
		src: image1,
		width: 6867,
		height: 10066
	},
	{
		src: image2,
		width: 6867,
		height: 10138
	},
	{
		src: image3,
		width: 6867,
		height: 10138
	},
	{
		src: image4,
		width: 10138,
		height: 6867
	}
];
