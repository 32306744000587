import image1 from '../../../images/photoProjects/blue-angels/1.jpg';
import image2 from '../../../images/photoProjects/blue-angels/2.jpg';
import image3 from '../../../images/photoProjects/blue-angels/3.jpg';

export const photosBlueAngels = [
	{
		src: image1,
		width: 4349,
		height: 1851
	},
	{
		src: image2,
		width: 3492,
		height: 1486
	},
	{
		src: image3,
		width: 3567,
		height: 4907
	}
];
